import flow from 'lodash/fp/flow'
import mapValues from 'lodash/fp/mapValues'
import pick from 'lodash/fp/pick'
import { observer } from 'mobx-react-lite'
import { useRouter } from 'next/router'
import { useContext, useEffect } from 'react'
import { CustomerDetails, CustomerStoreContext } from 'stores/CustomerStore'
import * as Yup from 'yup'

const emailSchema = Yup.string().email().optional()

/**
 * We want to allow deep linking to prefill user data, eg
 * https://www.mryum.com/fh?name=Andrei%20Miulescu&mobile=+61406569141&email=andrei@mryum.com
 */
export const UrlParamsSpy = observer(() => {
  const { setCustomerDetails } = useContext(CustomerStoreContext)
  const { query } = useRouter()

  const params: CustomerDetails = flow(
    pick(['name', 'mobile', 'email']),
    mapValues(decodeURIComponent),
  )(query as {})

  useEffect(() => {
    async function setDataFromParams() {
      try {
        if (params.email) {
          await emailSchema.validate(params.email)
        }
      } catch {
        delete params.email
      }

      if (Object.keys(params).length > 0) {
        setCustomerDetails(params)
      }
    }
    void setDataFromParams()
  }, [setCustomerDetails, params])

  return null
})

UrlParamsSpy.displayName = 'UrlParamsSpy'
