import Head from 'next/head'
import React from 'react'

import { MetaProps } from './types'
import buildTags from './utils'

export const Meta = ({
  title,
  noindex = true,
  nofollow,
  description,
  canonical,
  openGraph,
  facebook,
  twitter,
  additionalMetaTags,
  titleTemplate,
  mobileAlternate,
  languageAlternates,
}: MetaProps) => {
  return (
    <Head>
      {buildTags({
        title,
        noindex,
        nofollow,
        description,
        canonical,
        facebook,
        openGraph,
        additionalMetaTags,
        twitter,
        titleTemplate,
        mobileAlternate,
        languageAlternates,
      })}
    </Head>
  )
}
