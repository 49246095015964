import { GuestVenue, Maybe } from 'lib/gql'

export interface BrandingColors {
  primaryBrandingColor: string
  primaryBrandingText: string
  accentBrandingColor: string
  accentBrandingText: string
}

const primitives = {
  black: '#0D0D0D',
  white: '#FFFFFF',
  kale: '#095D44',
}

export const useVenueBrandingColors = (
  venue?: Maybe<
    Pick<
      GuestVenue,
      | 'brandingEnabled'
      | 'brandingPrimary'
      | 'brandingPrimaryText'
      | 'brandingAccent'
      | 'brandingAccentText'
      | 'brandingAccent'
      | 'brandingAccent'
      | 'brandingAccent'
      | 'brandingAccentText'
      | 'brandingPrimary'
    >
  >,
): {
  colors: BrandingColors
  hasCustomBranding: boolean
} => {
  let colors: BrandingColors = {
    primaryBrandingColor: primitives.black,
    primaryBrandingText: primitives.white,
    accentBrandingColor: primitives.kale,
    accentBrandingText: primitives.white,
  }

  const isCustomBrandingEnabled = !!venue?.brandingEnabled

  if (isCustomBrandingEnabled) {
    colors = {
      primaryBrandingColor: venue?.brandingPrimary || primitives.black,
      primaryBrandingText: venue?.brandingPrimaryText || primitives.white,
      accentBrandingColor: venue?.brandingAccent || primitives.kale,
      accentBrandingText: venue?.brandingAccentText || primitives.white,
    }
  }

  return { colors, hasCustomBranding: isCustomBrandingEnabled }
}
