import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useMemo,
} from 'react'

export type LoggerPayload = { [key: string]: any }

type LoggerContextValue = {
  properties?: LoggerPayload
}

export const LoggerContext = createContext<LoggerContextValue>({})

export const useLoggerContext = () => useContext(LoggerContext)

/*
 Provide properties to be included in all logs from children components.
 See `useLogger` hook's `logEvent` fn for performing the actual logging.
 Note: The `LoggerPayload` passed to `LoggerProvider` should be memoized.
 This will prevent unecessary re-renders and keep `logEvent` stable when
 used as a dependency for other hooks.
*/

export const LoggerProvider = ({
  children,
  properties,
}: PropsWithChildren<{ properties?: LoggerPayload }>) => {
  // include properties from parent providers
  const { properties: parentProperties } = useLoggerContext()

  const value = useMemo(
    () => ({
      properties: {
        ...parentProperties,
        ...properties,
      },
    }),
    [parentProperties, properties],
  )

  return (
    <LoggerContext.Provider value={value}>{children}</LoggerContext.Provider>
  )
}
