import React from 'react'

import { Meta } from './Meta'

export const DefaultMeta = () => (
  <Meta
    title="me&u"
    description="me&u helps you decide what to order. Beautiful, mobile menus with photos of every dish and language translations."
    openGraph={{
      title: 'me&u',
      site_name: 'me&u',
      description:
        'me&u helps you decide what to order. Beautiful, mobile menus with photos of every dish and language translations.',
    }}
  />
)
