import React, { PropsWithChildren } from 'react'

function isIPhoneX() {
  if (!process.browser) return false
  const anyWindow = window as any
  const iOS =
    /iPad|iPhone|iPod/.test(navigator.userAgent) && !anyWindow.MSStream
  let iPhoneX = false

  // Get the device pixel ratio
  const ratio = window.devicePixelRatio || 1

  // Define the users device screen dimensions
  const screen = {
    width: window.screen.width * ratio,
    height: window.screen.height * ratio,
  }

  // iPhone X Detection
  if (iOS && screen.width === 1125 && screen.height === 2436) {
    iPhoneX = true
  }

  return iPhoneX
}

function iOS() {
  if (!process.browser) return false
  return (
    [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod',
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  )
}

export const isTouchDevice = () => {
  if (!process.browser) return false
  return 'ontouchstart' in window || navigator.maxTouchPoints > 0
}

export const DeviceContext = React.createContext({
  isIPhoneX: false,
  iOS: false,
  isTouchDevice: false,
})

export const DeviceContextProvider = ({ children }: PropsWithChildren<{}>) => (
  <DeviceContext.Provider
    value={{
      isIPhoneX: isIPhoneX(),
      iOS: iOS(),
      isTouchDevice: isTouchDevice(),
    }}
  >
    {children}
  </DeviceContext.Provider>
)
