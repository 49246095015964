import { isClient } from '@mr-yum/frontend-core/dist/support/env'
import { loadStripe } from '@stripe/stripe-js/pure'
import { config } from 'lib/config'
import memoize from 'lodash/memoize'

export const CURRENCIES = [
  'AUD',
  'NZD',
  'USD',
  'GBP',
  'EUR',
  'CHF',
  'DKK',
  'NOK',
  'SEK',
  'SGD',
]

export const getStripePlatformAccountCountryCodeForCurrency = (
  currency: string,
) => {
  switch (currency) {
    case 'AUD':
      return 'AU'
    case 'NZD':
      return 'NZ'
    case 'USD':
      return 'US'
    case 'EUR':
      return 'GB'
    default:
      return 'GB'
  }
}

export const getStripeKeyForCurrency = (currency: string) => {
  switch (currency) {
    case 'AUD':
      return config.stripeAUD
    case 'NZD':
      return config.stripeNZD
    case 'GBP':
      return config.stripeGBP
    case 'EUR':
      return config.stripeEUR
    case 'CHF':
      return config.stripeCHF
    case 'DKK':
      return config.stripeDKK
    case 'NOK':
      return config.stripeNOK
    case 'SEK':
      return config.stripeGBP
    case 'SGD':
      return config.stripeSGD
    case 'USD':
      return config.stripeUSD
    default:
      return config.stripeAUD
  }
}

export const getStripeInstance = memoize((currency: string) =>
  isClient && CURRENCIES.includes(currency)
    ? loadStripe(getStripeKeyForCurrency(currency))
    : null,
)
