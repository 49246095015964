import Bugsnag from '@bugsnag/js'
import BugsnagReact from '@bugsnag/plugin-react'
import React from 'react'

import { appVersion, config, releaseStage } from './config'

if (process.env.NODE_ENV !== 'test') {
  // @ts-expect-error: Private property _client
  if (!Bugsnag._client) {
    Bugsnag.start({
      apiKey: config.bugsnagKey,
      releaseStage,
      appVersion,
      plugins: [new BugsnagReact(React)],
    })
  }
}

export { Bugsnag }

export const reportError = (error: Error) => {
  Bugsnag.notify(error)
}

export const reportErrorContext = (error: Error, context: string) => {
  Bugsnag.notify(error, (event) => {
    ;(event as any).context = context
  })
}
