import differenceInDays from 'date-fns/differenceInDays'
import differenceInHours from 'date-fns/differenceInHours'
import { reportErrorContext } from 'lib/bugsnag'
import { makeAutoObservable } from 'mobx'
import { makePersistable } from 'mobx-persist-store'
import React from 'react'
import { v4 } from 'uuid'

import { storage } from './utils'

export type BookingDetails = {
  roomName: string
  roomNumber: string
  timestamp: string
}

export type ClubMembershipDetails = {
  clubMembershipNumber: string
  timestamp: string
}

export type CustomerDetails = {
  name?: string
  mobile?: string
  email?: string
}

export type AnotherRoundModalStatus = {
  timestamp: string
  dismissed: boolean
}

export const ANOTHER_ROUND_MODAL_EXPIRY_HOURS = 48

class CustomerStore {
  sessionId = v4()

  skippedTableNumberModal = false

  viewedOrderingTypeModal = false

  anotherRoundModalStatus: Partial<Record<string, AnotherRoundModalStatus>> = {}

  customerDetails: CustomerDetails = {}

  /**
   * Store all booking details per venueSlug
   */
  bookingDetails: Record<string, BookingDetails> = {}

  /**
   * Store all club membership details per venueSlug
   */
  clubMembershipDetails: Record<string, ClubMembershipDetails> = {}

  setCustomerDetails(customerDetails: CustomerDetails) {
    this.customerDetails = { ...this.customerDetails, ...customerDetails }
  }

  showOrderingTypeModal = () => {
    this.viewedOrderingTypeModal = true
  }

  /**
   * Set booking details for a given venue
   */
  setBookingDetailsForVenueSlug({
    venueSlug,
    roomNumber,
    roomName,
  }: {
    venueSlug: string
  } & Omit<BookingDetails, 'timestamp'>) {
    this.bookingDetails[venueSlug] = {
      roomNumber,
      roomName,
      timestamp: String(new Date().getTime()),
    }
  }

  getBookingDetailsForVenueSlug = (venueSlug: string) => {
    const venueBookingDetails = this.bookingDetails[venueSlug]
    const diff = differenceInHours(
      new Date(),
      new Date(parseInt(venueBookingDetails?.timestamp || '')),
    )

    /**
     * If more than 24 hours ago, delete this entry
     */
    if (diff > 24) {
      delete this.bookingDetails[venueSlug]
      return
    }

    return venueBookingDetails
  }

  /**
   * Set booking details for a given venue
   */
  setClubMembershipDetailsForVenueSlug({
    venueSlug,
    clubMembershipNumber,
  }: {
    venueSlug: string
  } & Omit<ClubMembershipDetails, 'timestamp'>) {
    this.clubMembershipDetails[venueSlug] = {
      clubMembershipNumber,
      timestamp: String(new Date().getTime()),
    }
  }

  getClubMembershipDetailsForVenueSlug = (venueSlug: string) => {
    const venueClubMembershipDetails = this.clubMembershipDetails[venueSlug]
    const diff = differenceInDays(
      new Date(),
      new Date(parseInt(venueClubMembershipDetails?.timestamp || '')),
    )

    /**
     * If more than 28 days ago, delete this entry
     */
    if (diff > 28) {
      delete this.clubMembershipDetails[venueSlug]
      return
    }

    return venueClubMembershipDetails
  }

  setAnotherRoundModalStatusForVenueSlug({
    venueSlug,
    dismissed,
  }: {
    venueSlug: string
  } & Omit<AnotherRoundModalStatus, 'timestamp'>) {
    this.anotherRoundModalStatus[venueSlug] = {
      dismissed,
      timestamp: String(new Date().getTime()),
    }
  }

  getShowAnotherRoundModal(venueSlug: string): boolean {
    const anotherRoundModalStatus = this.anotherRoundModalStatus[venueSlug]

    if (!anotherRoundModalStatus) {
      return true
    }
    const diff = differenceInHours(
      new Date(),
      new Date(parseInt(anotherRoundModalStatus?.timestamp ?? '')),
    )

    /**
     * If more than X hours ago, delete this entry
     */
    if (diff > ANOTHER_ROUND_MODAL_EXPIRY_HOURS) {
      delete this.anotherRoundModalStatus[venueSlug]
      return true
    }

    return !anotherRoundModalStatus.dismissed
  }

  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true })
    makePersistable(
      this,
      {
        name: 'CustomerStore',
        properties: [
          'sessionId',
          'customerDetails',
          'bookingDetails',
          'clubMembershipDetails',
          'anotherRoundModalStatus',
        ],
        storage,
      },
      { delay: 300 },
    ).catch((error) => {
      reportErrorContext(error, 'mobx-persist-store')
    })
  }
}

export const customerStore = new CustomerStore()

export const CustomerStoreContext = React.createContext(customerStore)
