import * as Yup from 'yup'

export const VALID_LANGUAGES = [
  'en',
  'de',
  'fr',
  'el',
  'es',
  'zh',
  'it',
] as const

export type ValidLanguageFilters = (typeof VALID_LANGUAGES)[number]

export type Language = {
  value: ValidLanguageFilters
  shortLabel: string
  label: string
}

export const localeSchema = Yup.string()
  .oneOf(VALID_LANGUAGES)
  .transform((value: string) => {
    return value.split('-')[0]
  })
  .default('en')

export type ValidLocale = Yup.InferType<typeof localeSchema>

export const LanguageItems: Language[] = [
  {
    value: 'en',
    shortLabel: 'EN',
    label: 'English',
  },
  {
    value: 'de',
    shortLabel: 'DE',
    label: 'Deutsch',
  },
  {
    value: 'fr',
    shortLabel: 'FR',
    label: 'Français',
  },
  {
    value: 'el',
    shortLabel: 'EL',
    label: 'ελληνικά',
  },
  {
    value: 'zh',
    shortLabel: '中文',
    label: '中文',
  },
  {
    value: 'es',
    shortLabel: 'ES',
    label: 'Español',
  },
  {
    value: 'it',
    shortLabel: 'IT',
    label: 'Italiano',
  },
]

export const localeByVenueCurrency = (locale: string, currency?: string) => {
  if (locale === 'en' && currency === 'USD') {
    return 'en-US'
  }

  return locale
}
