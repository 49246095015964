import {
  flattenTokens,
  generateCustomThemeVariables,
  tokens,
} from '@mr-yum/frontend-ui'
import { BrandingColors } from 'hooks/useVenueBrandingColors'
import { shade, tint } from 'polished'
import React from 'react'

interface Props {
  colors: BrandingColors
  hasCustomBranding: boolean
}

export const ThemeVariables = ({ colors, hasCustomBranding }: Props) => {
  if (!hasCustomBranding) return null

  const updatedTokens = {
    ...tokens,
    color: {
      ...tokens.color,
      surface: {
        ...tokens.color.surface,
        'ui-navbar': {
          light: colors.primaryBrandingColor,
          dark: colors.primaryBrandingColor,
        },
      },
      foreground: {
        ...tokens.color.foreground,
        'ui-navbar': {
          light: colors.primaryBrandingText,
          dark: colors.primaryBrandingText,
        },
      },
      action: {
        ...tokens.color.action,
        primary: {
          foreground: {
            light: colors.accentBrandingText,
            dark: colors.accentBrandingText,
          },
          surface: {
            light: colors.accentBrandingColor,
            dark: colors.accentBrandingColor,
          },
          'surface-hover': {
            light: shade(0.1, colors.accentBrandingColor),
            dark: shade(0.1, colors.accentBrandingColor),
          },
        },
        tonal: {
          foreground: {
            light: shade(0.8, colors.accentBrandingColor),
            dark: shade(0.8, colors.accentBrandingColor),
          },
          surface: {
            light: tint(0.4, colors.accentBrandingColor),
            dark: tint(0.4, colors.accentBrandingColor),
          },
          'surface-hover': {
            light: tint(0.3, colors.accentBrandingColor),
            dark: tint(0.3, colors.accentBrandingColor),
          },
        },
        'ghost-primary': {
          foreground: {
            light: shade(0.1, colors.accentBrandingColor),
            dark: shade(0.1, colors.accentBrandingColor),
          },
          'surface-hover': {
            light: tint(0.4, colors.accentBrandingColor),
            dark: tint(0.4, colors.accentBrandingColor),
          },
        },
        'text-primary': {
          foreground: {
            light: shade(0.1, colors.accentBrandingColor),
            dark: shade(0.1, colors.accentBrandingColor),
          },
        },
        'text-secondary': {
          foreground: {
            light: shade(0.1, colors.accentBrandingColor),
            dark: shade(0.1, colors.accentBrandingColor),
          },
        },
      },
      border: {
        ...tokens.color.border,
        focus: {
          light: colors.accentBrandingColor,
          dark: colors.accentBrandingColor,
        },
      },
      interactive: {
        ...tokens.color.interactive,
        default: {
          ...tokens.color.interactive.default,
          'foreground-selected': {
            light: shade(0.1, colors.accentBrandingColor),
            dark: shade(0.1, colors.accentBrandingColor),
          },
          'surface-active': {
            light: colors.accentBrandingColor,
            dark: colors.accentBrandingColor,
          },
          'surface-selected': {
            light: tint(0.4, colors.accentBrandingColor),
            dark: tint(0.4, colors.accentBrandingColor),
          },
          'surface-switcher-selected': {
            light: colors.accentBrandingColor,
            dark: colors.accentBrandingColor,
          },
        },
      },
    },
  }
  const flattenedTokens = flattenTokens(updatedTokens.color)
  const variables = generateCustomThemeVariables(flattenedTokens)

  return (
    <style>
      {`
      :root {
        ${variables[':root']}
      }
      .dark {
        ${variables['.dark']}
      }
    `}
    </style>
  )
}
