import { isServer } from '@mr-yum/frontend-core/dist/support/env'
import NodeCache from 'node-cache'

const fallbackCache = new Map()

export const serverCache = isServer
  ? new NodeCache({
      maxKeys: 5000,
      stdTTL: 300, // 5 minutes
      checkperiod: 30, // 30 seconds
    })
  : {
      get: (key: string) => fallbackCache.get(key),
      set: (key: string, value: any) => fallbackCache.set(key, value),
    }
