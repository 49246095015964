import { StorageController } from 'mobx-persist-store'
import store from 'store/dist/store.modern.min'

const storeStorageController: StorageController = {
  getItem(key: string) {
    return store.get(key)
  },

  setItem(key: string, value: any) {
    return store.set(key, value)
  },

  removeItem(key: string) {
    return store.remove(key)
  },
}

export const storage =
  process.env.NODE_ENV === 'test' ? localStorage : storeStorageController

export const setLocalStorageWithExpiry = (
  key: string,
  value: any,
  ttlMs: number,
) => {
  const now = new Date()

  const item = { value: value, expiry: now.getTime() + ttlMs }

  localStorage.setItem(key, JSON.stringify(item))
}

export const getLocalStorageWithExpiry = (key: string) => {
  const itemStr = localStorage.getItem(key)

  if (!itemStr) {
    return null
  }

  const item = JSON.parse(itemStr)
  const now = new Date()

  if (now.getTime() > item.expiry) {
    localStorage.removeItem(key)
    return null
  }

  return item.value
}
