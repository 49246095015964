import { isServer } from '@mr-yum/frontend-core/dist/support/env'
import getConfig from 'next/config'

const { publicRuntimeConfig } = getConfig()
const {
  APP_VERSION,
  APP_ENV,
  API_URL,
  REGION,
  SSR_API_URL,
  MANAGE_API_URL,
  CLIENT_URQL_TIMEOUT,
  SSR_URQL_TIMEOUT,
} = publicRuntimeConfig

// this will only apply during SSR, getGuestGatewayUrl() will run for client-side and override this value
const apiUrl = isServer ? SSR_API_URL : API_URL
const region = REGION
export const appVersion = APP_VERSION
const manageApiUrl = MANAGE_API_URL

const clientUrqlTimeoutMilliseconds = CLIENT_URQL_TIMEOUT || 90000
const ssrUrqlTimeoutMilliseconds = SSR_URQL_TIMEOUT || 30000

const urqlTimeoutMilliseconds = isServer
  ? ssrUrqlTimeoutMilliseconds
  : clientUrqlTimeoutMilliseconds

const clientVersionFetchIntervalMinutes = 60

const mockEonxApiCalls = false

interface ConfigValue {
  baseUrl: string
  environment: string
  apiUrl: string
  region: string
  /** we only need this for QR code generation */
  manageApiUrl: string
  gaTrackingID: string
  bugsnagKey: string
  lokeClientId: string
  googlePlacesApiKey: string
  amplitudeKey: string
  stripeAUD: string
  stripeNZD: string
  stripeUSD: string
  stripeGBP: string
  stripeEUR: string
  stripeCHF: string
  stripeDKK: string
  stripeNOK: string
  stripeSEK: string
  stripeSGD: string
  visitorsUrl: string
  paystackZAR: string
  pixboostAuth: string
  pixboostUrl: string
  cloudflareImageResizingUrl: string
  urqlTimeoutMilliseconds: number
  appVersion: string
  mockEonxApiCalls: boolean
  turnstileSiteKey: string
  clientVersionFetchIntervalMinutes: number
}

interface ConfigData {
  staging: ConfigValue
  test: ConfigValue
  development: ConfigValue
  production: ConfigValue
}
const stripeUKTest =
  'pk_test_51HlWdID0luSv8eqWyu6PAk0RiZ3X8omrb07R2kPyCmYNSDxBmi1IClnQsB8FUupa4hZ3ocHlzGBXbS4C8QQoeCPC00NRane1eD'

const stripeTestKeys = {
  stripeAUD: 'pk_test_gtRdjjtoOFsZqEvtkSD4sVir',
  stripeNZD: 'pk_test_jiNkiilHj7n4JY52tCJOu3fo00ZUnEYwWi',
  stripeUSD:
    'pk_test_51Hbe2QEvPYHKZhcgO40TnOTETuPgRlYgqb26TZsn4Rlz2Qjv25rTYDRO6303qlzE6LaBu6QBfy4VXWWs7lU4bLG900K6ZDig1s',
  stripeSGD:
    'pk_test_51JyKqHBshqr84imdjweJ1tFyfDo0av90aoYFX4L4D3EB7RF60Az5lDVeDGeoynCCxJhicudYNlBrLEYN2thsZ1mu00LprRwf1s',
  stripeGBP: stripeUKTest,
  stripeEUR: stripeUKTest,
  stripeCHF: stripeUKTest,
  stripeDKK: stripeUKTest,
  stripeNOK: stripeUKTest,
  stripeSEK: stripeUKTest,
}

const stripeUKLive =
  'pk_live_51HlWdID0luSv8eqWOdcn3Sa3lbvymorvXqgkma1CohZF3hfDPu5OsaU2ivGfTLWAesYyE7muXyOlbwEtR5OAio8g003mey2VY5'
const stripeLiveKeys = {
  stripeAUD: 'pk_live_ifyBluZAKnJmYbduIOVv512K',
  stripeNZD: 'pk_live_cx5rxJ1Hm0DFjta5u6VC5aGl00NCZaDy4L',
  stripeUSD:
    'pk_live_51Hbe2QEvPYHKZhcgWHpYhdjczuPzSxTeIW0piCHrQCDaDTALJy3lcobvLpewB8HXbAJx7FOpOCNjiQUnQxCuATRL00NH0KlZcE',
  stripeSGD:
    'pk_live_51JyKqHBshqr84imdw6NMA3QmHa2J8f2N3B3lk8TG3JHLyWQEMlFcjPQixghOZtQpmmOPUddglwaInG0mq96yJLwV00NIJcKSwm',
  stripeGBP: stripeUKLive,
  stripeEUR: stripeUKLive,
  stripeCHF: stripeUKLive,
  stripeDKK: stripeUKLive,
  stripeNOK: stripeUKLive,
  stripeSEK: stripeUKLive,
}

const paystackTestKeys = {
  paystackZAR: 'pk_test_b96c359b0acb27e42f16ae1de3286d33e1eebec9',
}

const paystackLiveKeys = {
  paystackZAR: 'pk_live_6299396e3df045e25e5da5c2a28ce78f6303a1d8',
}

const configData: ConfigData = {
  staging: {
    baseUrl: apiUrl ? 'https://staging.meandu.app' : 'https://dev.meandu.app',
    environment: 'staging',
    // apiURL is undefined in local dev as there's no helmfile suppling the ENV vars
    apiUrl: apiUrl ?? 'https://ap1-guest-gateway.staging.meandu.app',
    region,
    manageApiUrl: manageApiUrl || 'https://staging-api.mryum.com',
    gaTrackingID: 'G-2T04XWG556',
    bugsnagKey: 'd98473b2ff9263ec37e14748a0c7b13c',
    lokeClientId: 'aafe9a5e9e76ca3aa4d7',
    googlePlacesApiKey: 'AIzaSyA8kTkrv_P9n9dcvkPQsHtAgn896ZllPJI',
    amplitudeKey: '252de61451f065e558acba22d7286037',
    visitorsUrl: 'https://visitors.staging.meandu.app',
    pixboostAuth: 'NDI4ODAwMzkxNg__',
    pixboostUrl: 'https://images.mryum.com/api/2/img',
    cloudflareImageResizingUrl: 'https://staging.mryum.com/cdn-cgi/image',
    ...stripeTestKeys,
    ...paystackTestKeys,
    urqlTimeoutMilliseconds,
    appVersion,
    mockEonxApiCalls,
    turnstileSiteKey: '0x4AAAAAAAEWF2SUfnTLD0l1',
    clientVersionFetchIntervalMinutes,
  },
  production: {
    baseUrl: 'https://www.meandu.app',
    environment: 'production',
    apiUrl,
    region,
    manageApiUrl,
    gaTrackingID: 'G-LTF2KG0LW3',
    bugsnagKey: 'd98473b2ff9263ec37e14748a0c7b13c',
    lokeClientId: 'fe8ac94bf95bfed70a3d',
    googlePlacesApiKey: 'AIzaSyA8kTkrv_P9n9dcvkPQsHtAgn896ZllPJI',
    amplitudeKey: '67e3ef17332cfbe053505228275d61b6',
    visitorsUrl: 'https://visitors.mryum.com',
    pixboostAuth: 'NDI4ODAwMzkxNg__',
    pixboostUrl: 'https://images.mryum.com/api/2/img',
    cloudflareImageResizingUrl: 'https://www.mryum.com/cdn-cgi/image',
    ...stripeLiveKeys,
    ...paystackLiveKeys,
    urqlTimeoutMilliseconds,
    appVersion,
    mockEonxApiCalls,
    turnstileSiteKey: '0x4AAAAAAAEWF2SUfnTLD0l1',
    clientVersionFetchIntervalMinutes,
  },
  test: {
    environment: 'test',
    baseUrl: 'https://mryum.local',
    apiUrl: 'https://gateway.mryum.local',
    region,
    manageApiUrl: 'https://api.mryum.local',
    gaTrackingID: 'UA-129043240-3',
    bugsnagKey: '00000000000000000000000000000000',
    lokeClientId: 'aafe9a5e9e76ca3aa4d7',
    googlePlacesApiKey: 'AIzaSyA8kTkrv_P9n9dcvkPQsHtAgn896ZllPJI',
    amplitudeKey: '252de61451f065e558acba22d7286037',
    visitorsUrl: 'https://visitors.staging.meandu.app',
    pixboostAuth: 'NDI4ODAwMzkxNg__',
    pixboostUrl: 'https://images.mryum.com/api/2/img',
    cloudflareImageResizingUrl: 'https://staging.mryum.com/cdn-cgi/image',
    ...stripeTestKeys,
    ...paystackTestKeys,
    urqlTimeoutMilliseconds,
    appVersion,
    mockEonxApiCalls: true,
    turnstileSiteKey: '1x00000000000000000000AA',
    clientVersionFetchIntervalMinutes,
  },
  development: {
    baseUrl: 'https://mryum.local',
    environment: 'development',
    apiUrl: 'https://mryum.local/guest-gateway/dev',
    region: 'dev',
    manageApiUrl: 'https://api.mryum.local',
    gaTrackingID: 'UA-129043240-3',
    bugsnagKey: '00000000000000000000000000000000',
    lokeClientId: 'aafe9a5e9e76ca3aa4d7',
    googlePlacesApiKey: 'AIzaSyA8kTkrv_P9n9dcvkPQsHtAgn896ZllPJI',
    amplitudeKey: 'c5c4395ae14a89f05f89098e1cd991f4',
    visitorsUrl: 'https://dev-visitors.mryum.com',
    pixboostUrl: 'https://images.mryum.com/api/2/img',
    cloudflareImageResizingUrl: 'https://staging.mryum.com/cdn-cgi/image',
    pixboostAuth: 'NDI4ODAwMzkxNg__',
    ...stripeTestKeys,
    ...paystackTestKeys,
    urqlTimeoutMilliseconds,
    appVersion,
    mockEonxApiCalls: false,
    turnstileSiteKey: '1x00000000000000000000AA',
    clientVersionFetchIntervalMinutes,
  },
}

export type ValidConfigKeys = keyof ConfigData

export const releaseStage: ValidConfigKeys =
  (APP_ENV as ValidConfigKeys) || 'development'

if (!(releaseStage in configData)) {
  throw new Error(`APP_ENV does not exist in config: ${releaseStage}`)
}

export const config = configData[releaseStage]
