import { OrderingType } from 'lib/gql'
import { getOrderingSlugFromType, OrderingTypeSlug } from 'lib/routes'
import { CartDefaults } from 'lib/utils'
import { ParsedUrlQuery } from 'querystring'

export const getNestedVenueSlug = (query: ParsedUrlQuery | string) => {
  if (typeof query === 'string') {
    // remove any duplicate slashes
    let path = query.replace('//', '/')

    // extracting nested path for MVO
    const nestedPathRexExp = /^\/[^/]+?\/o\/[^/]+?(\/.*)/
    const nestedPath = nestedPathRexExp.exec(path)
    if (!!nestedPath && nestedPath.length > 1) {
      path = nestedPath[1]
    }

    const slug = path.split('/').filter((v) => !!v && v !== '/')
    return slug[0] ? slug[0].toLowerCase() : null
  }

  if (query.venueSlugNested) {
    return String(query.venueSlugNested).toLowerCase()
  } else if (query.venueSlug) {
    return String(query.venueSlug).toLowerCase()
  } else {
    return null
  }
}

export const getParentVenueSlug = (query: ParsedUrlQuery) => {
  if (query.venueSlug) {
    return String(query.venueSlug).toLowerCase()
  } else if (query.venueSlugNested) {
    return String(query.venueSlugNested).toLowerCase()
  } else {
    return null
  }
}

// should only be used when we know venueSlug and orderingTypeSlug WILL be in the query
export const getVenueAndOrderingTypeFromQuery = (
  query: ParsedUrlQuery,
): { venueSlug: string; orderingTypeSlug: OrderingTypeSlug } => {
  return {
    venueSlug: getNestedVenueSlug(query) ?? '',
    orderingTypeSlug: query.orderingTypeSlug as OrderingTypeSlug,
  }
}

export const getTableInfo = (query: ParsedUrlQuery): CartDefaults => {
  return {
    tableNumber: query.number ? String(query.number).toUpperCase() : undefined,
    tableArea: query.area ? String(query.area) : undefined,
    googlePlaceId: query.place ? String(query.place) : undefined,
  }
}

export const getDefaultOrderingTypeSlug = (
  orderingType?: OrderingType | null,
) => {
  return getOrderingSlugFromType(orderingType ?? OrderingType.Menu)
}
