import { isClient } from '@mr-yum/frontend-core/dist/support/env'
import { localeSchema, ValidLanguageFilters } from 'components/Lang/utils'
import { reportErrorContext } from 'lib/bugsnag'
import { makeAutoObservable } from 'mobx'
import { makePersistable } from 'mobx-persist-store'
import { computedFn } from 'mobx-utils'
import React from 'react'

import { DietaryPref } from './enums/dietaryPreferences'
import { storage } from './utils'

export class MenuStore {
  // Search Query
  searchQuery = ''

  setSearchQuery(query: string) {
    this.searchQuery = query
  }

  // Language
  language: ValidLanguageFilters = 'en'

  setLanguage(locale: ValidLanguageFilters) {
    this.language = locale
  }

  // Dietary Preferences
  dietaryPrefs: DietaryPref[] = []

  /**
   * Toggle a dietary preference
   */
  toggleDietaryPref(pref: DietaryPref) {
    const i = this.dietaryPrefs.indexOf(pref)
    if (i === -1) {
      this.dietaryPrefs.push(pref)
    } else {
      this.dietaryPrefs.splice(i, 1)
    }
  }

  /**
   * Set dietary preference
   */
  setDietaryPrefs(newDietaryPrefs: DietaryPref[]) {
    this.dietaryPrefs = newDietaryPrefs
  }

  get hasDietaryPrefs() {
    return this.dietaryPrefs.length > 0
  }

  /**
   * Check if a dietary preference is active
   */
  isDietaryPrefActive: (pref: DietaryPref) => boolean = computedFn(
    (pref: DietaryPref) => this.dietaryPrefs.indexOf(pref) > -1,
  )

  // Current Section
  currentSection = ''

  /**
   *
   * @param section Generated slug of the currently viewed section
   * from category + section name to `kebabCase` eg `late-night-food/small-plates`
   */
  setCurrentSection(section: string) {
    this.currentSection = section
  }

  /**
   * Multi Vendor Search
   */
  categorySearch = ''

  setCategorySearch(query: string) {
    this.categorySearch = query
  }

  constructor() {
    /**
     * We need to detect the user's preferred language
     * this will only apply if the user is new, once they have a set language
     * we use their preference as makePersistable will be called next
     */
    if (isClient && localeSchema.isValidSync(navigator.language)) {
      const locale = localeSchema.cast(navigator.language)
      this.setLanguage(locale)
    }

    makeAutoObservable(this, undefined, { autoBind: true })
    makePersistable(
      this,
      {
        name: 'MenuStore',
        properties: ['language', 'dietaryPrefs'],
        storage,
      },
      { delay: 300 },
    ).catch((error) => {
      reportErrorContext(error, 'mobx-persist-store')
    })
  }
}

export const menuStore = new MenuStore()

export const MenuStoreContext = React.createContext(menuStore)
