import { navigate } from '@mr-yum/frontend-core/dist/services/routes'
import { routes } from 'lib/routes'
import { useEffect } from 'react'

declare global {
  interface Document {
    documentMode?: any | undefined
  }
}

const isOutdatedBrowser = () => {
  // Tests IE 11 down
  if (window.document.documentMode) {
    return true
  }

  // Tests Proxy
  if (typeof Proxy === 'undefined') {
    return true
  }

  return false
}

export const useNotOutdatedBrowser = (asPath: string) => {
  useEffect(() => {
    if (isOutdatedBrowser() && asPath !== '/sorry') {
      void navigate(routes.sorry)
    }
  })
}
